import { Logo as DsLogo } from '@change/tomato-core-ui';

import { useLogo } from './hooks/useLogo';

export const Logo: React.FC = () => {
	const {
		data: { logoUrl },
	} = useLogo();

	return <DsLogo src={logoUrl} href="/?redirect=false" color="primary" size="small" aria-label="Change.org" />;
};
