import gql from 'graphql-tag';

import type { Utilities, UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { TopicFromSlugQuery } from './index.graphql';

export async function getTopicSlug(
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
	slug: string,
): Promise<{ slug: string | undefined }> {
	const { data } = await utilities.gql.fetch<TopicFromSlugQuery>({
		query: gql`
			query TopicFromSlug($slug: String!) {
				topic: topicPageBySlug(slug: $slug) {
					slug
					id
				}
			}
		`,
		variables: { slug },
	});
	return { slug: data?.topic?.slug ?? undefined };
}
