import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { useProcessedImageUrl } from 'src/app/shared/hooks/image';
import { useCurrentUser } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useFcm } from '../../../../../../../shared/fcm';
import { logoutUrl } from '../../../../../../../shared/navigation';
import { useHeaderNavLinks } from '../../../../../../shared/hooks';
import type { HeaderNavLink } from '../../../../../../shared/hooks';

type Result = ModelHookResult<{
	atLeastIdentified: boolean;
	avatarUrl: string | undefined;
	userName: string;
	logoutUrl: string;
	navLinks: readonly HeaderNavLink[];
}>;

export function useDropdown({ mobile, hideBrowseLink }: { mobile: boolean; hideBrowseLink: boolean }): Result {
	const currentUser = useCurrentUser();
	const fcmState = useFcm();
	const location = useLocation();

	const navLinks = useHeaderNavLinks();

	const userAvatarUrl = useProcessedImageUrl(currentUser?.photo?.userMedium);
	const isMembershipEnabled = isLoaded(fcmState) && fcmState.values.membershipEnabled;

	return {
		data: {
			atLeastIdentified: !!currentUser,
			avatarUrl: userAvatarUrl,
			userName: currentUser?.displayName || '',
			logoutUrl: logoutUrl({ redirectTo: location }),
			navLinks: useMemo(
				() => [
					...(mobile ? [navLinks.startAPetition] : []),
					...(currentUser ? [navLinks.myPetitionsFromDropdown] : []),
					...(mobile && isMembershipEnabled ? [navLinks.membership] : []),
					...(mobile && !hideBrowseLink ? [navLinks.browse] : []),
					...(mobile ? [navLinks.search] : []),
					...(currentUser ? [navLinks.settings] : []),
				],
				[navLinks, isMembershipEnabled, currentUser, mobile, hideBrowseLink],
			),
		},
	};
}
