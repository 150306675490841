import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

export type HeaderNavLink = {
	href: string;
	label: string;
	dataQa: string;
	onClick?: () => void;
};

type Result = Record<
	'startAPetition' | 'myPetitions' | 'myPetitionsFromDropdown' | 'membership' | 'browse' | 'search' | 'settings',
	HeaderNavLink
>;

export function useHeaderNavLinks(): Result {
	const { translate } = useI18n();
	const track = useTracking();

	return useMemo(
		() => ({
			startAPetition: {
				href: '/start-a-petition?source_location=header',
				label: translate('fe.components.header.start_a_petition'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				onClick: () => {
					void track('nav_sap_click');
				},
				dataQa: 'header-sap-link',
			},
			// TODO check if we can merge those following 2 links
			myPetitions: {
				href: '/u/me',
				label: translate('fe.components.header.my_petitions'),
				dataQa: 'header-my-petitions-link',
			},
			myPetitionsFromDropdown: {
				href: '/u/me?source_location=my_petitions_dropdown',
				label: translate('fe.components.header.my_petitions'),
				dataQa: 'header-my-petitions-link',
			},
			membership: {
				href: '/member/join?source_location=member_link_header',
				label: translate('fe.components.header.membership'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				dataQa: 'header-membership-link',
			},
			browse: {
				href: '/browse',
				label: translate('fe.components.header.browse'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				dataQa: 'header-browse-link',
			},
			search: {
				href: '/search',
				label: translate('fe.components.header.search'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				dataQa: 'header-search-link',
			},
			settings: {
				href: '/account_settings',
				label: translate('fe.components.header.settings'),
				dataQa: 'header-settings-link',
			},
		}),
		[translate, track],
	);
}
