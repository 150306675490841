import React from 'react';

import { ThemeProvider } from '@change/tomato-core-ui';

import { useDs2Experiment } from './useDs2Experiment';

export const Ds2ExperimentTheme: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { isEnabled } = useDs2Experiment();

	if (isEnabled) {
		return <ThemeProvider>{children}</ThemeProvider>;
	}

	return children;
};
