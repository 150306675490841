import { useMemo } from 'react';

import { useDs2ExperimentContext } from './context';

type KnownVariations = 'control' | 'treatment';

type Result = {
	/**
	 * `true` if the variation is `'treatment'`
	 */
	isEnabled: boolean;
	/**
	 * The actual variation string. Currently limited to 'treatment' or 'control', with 'control' being the default if
	 * the experiment context was not configured.
	 */
	variation: KnownVariations;
};

const identifyKnownVariation = (rawVariation?: string): KnownVariations =>
	rawVariation === 'treatment' ? 'treatment' : 'control';

/**
 * This hook can be used in shared code to decide whether `@change/tomato` components can be shown.
 *
 * If this hook is called within a page that is not set up to fetch & provide the experiment state, it will return the
 * default experiment state state: `{ isEnabled: false, variation: 'control' }`. This is by design because pages to have
 * to opt-in to DS2 (by including the required providers). But we want to allow shared code to check for this experiment
 * without worrying about whether the page has opted in yet.
 *
 * See: [README](./README.md)
 */
export const useDs2Experiment = (): Result => {
	const contextValue = useDs2ExperimentContext();
	const rawVariation = contextValue?.ds2FcmExperiment?.variation;

	const result: Result = useMemo(() => {
		const variation = identifyKnownVariation(rawVariation);
		return {
			isEnabled: variation === 'treatment',
			variation,
		};
	}, [rawVariation]);

	return result;
};
