import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

/**
 * This FCM controls the name (id) of the experiment that is checked for percent rollout of variants (variations). This
 * experiment is intended to be used by shared components that are used in multiple pages.
 *
 * The initial approach is to have only a single experiment that controls whether the DS2 variants are shown. We could
 * get more fine-grained control by using multiple experiments, or variants, or options within the FCM. But we don't
 * want to end up with so many partial redesigns that we can't keep track of what's visible to users.
 */
export const FCM_DS2_EXPERIMENT = createFcmConfig('ds2_experiment', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				experimentName: { type: 'string', optional: true },
			},
		}),
	),
	defaultValue: undefined,
});
