import {
	FCM_ENABLE_BLACK_HISTORY_LOGO,
	FCM_ENABLE_CLIMATE_STRIKE_LOGO,
	FCM_ENABLE_PRIDE_LOGO,
	FCM_ENABLE_SUBSCRIPTIONS_FOR,
} from '@change-corgi/config/fcm/configs';

import { FCM_CORGI_FRAME_RELAUNCH, FCM_HEADER_HIDE_BROWSE_LINK } from 'src/app/shared/fcm/configs';
import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm, prefetchFcm } = createFcmFunctions(
	{
		showBlackHistoryLogo: FCM_ENABLE_BLACK_HISTORY_LOGO,
		showPrideLogo: FCM_ENABLE_PRIDE_LOGO,
		showClimateStrikeLogo: FCM_ENABLE_CLIMATE_STRIKE_LOGO,
		membershipEnabled: FCM_ENABLE_SUBSCRIPTIONS_FOR,
		frameRelaunch: FCM_CORGI_FRAME_RELAUNCH,
		hideBrowseLink: FCM_HEADER_HIDE_BROWSE_LINK,
	},
	{ hook: true, prefetch: true },
);
