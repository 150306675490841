import type { LoginState } from '@change-corgi/middle-end-gql-schema';

import type { SessionUser } from 'src/shared/session';

import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import type { SupporterComment } from '../../types';

type Result = { canReportComment: boolean; currentUser: SessionUser | undefined; loginState: LoginState | undefined };

export function useCanReportComment(comment: SupporterComment | null): Result {
	// TODO(CHANGE-67838): Remove unnecessary async checking
	const currentUserBase = useCurrentUserAsync();
	const loginStateBase = useLoginStateAsync();

	let canReportComment = false;

	if (!isLoading(currentUserBase) && !isLoading(loginStateBase) && comment) {
		const isCommentOwner = currentUserBase.value?.id === comment.user?.id;
		canReportComment = !isCommentOwner && loginStateBase.value !== 'GUEST';
	}

	return {
		canReportComment,
		currentUser: isLoaded(currentUserBase) ? currentUserBase.value : undefined,
		loginState: isLoaded(loginStateBase) ? loginStateBase.value : undefined,
	};
}
