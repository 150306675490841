import { useRef } from 'react';
import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';

import type { Session } from 'src/shared/session';
import { isAtLeastIdentified } from 'src/shared/session';

import { useProcessedImageUrl } from 'src/app/shared/hooks/image';

import { useDropDownRef } from '../hooks/useDropDownRef';

import { DropDownButton } from './DropDownButton';
import { DropDownMenu } from './DropDownMenu';

type Props = {
	headerHeights: ResponsiveValue<number | string>;
	session: Session;
	isMembershipEnabled: boolean;
	showBrowseLink: boolean;
};

const DEFAULT_AVATAR = 'https://static.change.org/profile-img/default-user-profile.svg';

export function DropDown({ headerHeights, session, isMembershipEnabled, showBrowseLink }: Props): JSX.Element | null {
	const { ref, isOpen, toggleDropDown } = useDropDownRef();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const userAvatarUrl = useProcessedImageUrl(session.user?.photo?.userMedium);

	const toggleDropDownAndFocus = () => {
		toggleDropDown();
		buttonRef?.current?.focus();
	};

	return (
		<Box sx={{ position: 'relative' }} ref={ref}>
			<DropDownButton
				avatarUrl={userAvatarUrl || DEFAULT_AVATAR}
				headerHeights={headerHeights}
				isAtLeastIdentified={isAtLeastIdentified(session)}
				expanded={isOpen}
				onClick={toggleDropDown}
				ref={buttonRef}
			/>
			{isOpen && (
				<DropDownMenu
					displayName={(isAtLeastIdentified(session) && session.user.displayName) || undefined}
					isAtLeastIdentified={isAtLeastIdentified(session)}
					onClose={toggleDropDownAndFocus}
					isMembershipEnabled={isMembershipEnabled}
					showBrowseLink={showBrowseLink}
				/>
			)}
		</Box>
	);
}
