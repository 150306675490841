import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		experimentName: { type: 'string', optional: true },
	},
});

export const FCM_NEW_PETITION_PAGE_REMOVE_RFS_EXPERIMENT = createFcmConfig('new_petition_page_remove_rfs_experiment', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		experimentName: undefined,
	} satisfies ReturnType<typeof normalizer>,
});
