import type { ComponentProps, JSX } from 'react';
import { Fragment, useCallback } from 'react';

import qs from 'qs';

import { nonBetaLocalesInfo } from '@change-corgi/config/locales';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { getLocation } from '@change-corgi/core/window';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Heading } from '@change-corgi/design-system/typography';

import { LocaleSelect } from 'src/app/shared/components/l10n';
import { useLocale } from 'src/app/shared/hooks/l10n';

import { type FooterLinkGroup, useFooterLinks } from '../../../shared/hooks';
import { Copyright } from '../components/Copyright';
import { getCurrentYear } from '../shared/date-utils';

export function FooterContainer(props: ComponentProps<typeof Box>): JSX.Element | null {
	const locale = useLocale();
	const locales = nonBetaLocalesInfo;
	const year = getCurrentYear();

	const onLocaleChange = useCallback((newLocale: string) => {
		const location = getLocation();
		const search = qs.parse(location.search, { ignoreQueryPrefix: true });
		search.lang = newLocale;
		location.assign(`${location.origin}${location.pathname}?${qs.stringify(search)}${location.hash}`);
	}, []);

	return (
		<Box as="footer" {...props}>
			<Separator role="presentation" my={0} />

			<Box
				sx={{
					// TODO change color to use DS color (check with design team)
					backgroundColor: '#f6f4f6',
				}}
				py={16}
			>
				<Container px={[16, 8]}>
					<Links />

					<Box sx={{ display: ['block', 'none'] }} mt={32}>
						<LocaleSelect
							id="app-locale-select-mobile"
							locales={locales}
							currentLocale={locale}
							onLocaleChange={onLocaleChange}
						/>
					</Box>
				</Container>

				<Container px={[16, 8]}>
					<Separator role="presentation" mt={[32, 24]} mb={16} />
					<Flex sx={{ alignItems: 'center' }}>
						<Box sx={{ flex: [1, 3] }} mt={[0, 8]}>
							<Copyright year={year} />
						</Box>
						<Box sx={{ display: ['none', 'block'], flex: 1 }} pl={24}>
							<LocaleSelect
								id="app-locale-select"
								locales={locales}
								currentLocale={locale}
								onLocaleChange={onLocaleChange}
							/>
						</Box>
					</Flex>
				</Container>
			</Box>
		</Box>
	);
}

function Links() {
	const ungroupedLinks = useFooterLinks();
	const groupedLinks = ungroupedLinks
		.filter((n) => n)
		.reduce<FooterLinkGroup[][]>((acc, links, idx) => {
			if (idx % 2 === 0) {
				acc.push([links]);
			} else {
				acc[acc.length - 1].push(links);
			}
			return acc;
		}, []);

	return (
		<Flex sx={{ flexDirection: ['column', 'row'], gap: [16, 8] }}>
			{groupedLinks.map((linksGroup, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<Flex sx={{ flex: 1, gap: 8 }} key={index}>
					{/* eslint-disable-next-line @typescript-eslint/no-shadow */}
					{linksGroup.map((links, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Box sx={{ flex: 1 }} key={index} mt={24}>
							<Heading as="h2" size="h5" color="neutral-grey700" mb={16}>
								{links.title}
							</Heading>
							<Box as="ul">
								{/* eslint-disable-next-line @typescript-eslint/no-shadow */}
								{links.links.map((link, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<Fragment key={index}>
										<Box as="li" mt={8}>
											<RouterLink
												to={link.href}
												target={link.target}
												sx={{
													...linkResetStyles,
													display: 'inline-block',
													height: '100%',
													// eslint-disable-next-line @typescript-eslint/naming-convention
													'&:hover': {
														color: 'primary-changeRed',
													},
												}}
												aria-label="Change.org"
												data-qa="header-home-link"
											>
												{link.title}
											</RouterLink>
										</Box>
									</Fragment>
								))}
							</Box>
						</Box>
					))}
				</Flex>
			))}
		</Flex>
	);
}
