import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { FCM_POLICY_ABUSE_PETITION_REPORT } from 'src/app/shared/fcm/configs/configs/policy_abuse_petition_report';

export async function isPolicyAbuseExperimentEnabled(utilityContext: UtilityContext) {
	const fcm = await utilityContext.fcm.get({
		policyAbusePetitionReport: FCM_POLICY_ABUSE_PETITION_REPORT,
	});
	const experiment = utilityContext.experiments.get(
		fcm.policyAbusePetitionReport?.experimentName || FCM_POLICY_ABUSE_PETITION_REPORT.name,
	);
	// Verify the FCM is enabled
	if (!fcm.policyAbusePetitionReport?.enabled) return false;
	// Treating the experiment ONLY if the FCM is enabled
	void experiment.treat();
	return experiment.variation !== 'control' && experiment.variation !== 'fake_control';
}
