import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useCurrentAuthenticatedUser } from 'src/app/shared/hooks/session';

import type { ReportContext } from '../../shared/types';

type Result = ModelHookResult<
	undefined,
	{
		trackReportAbuse: (context: { success: boolean }) => void;
	}
>;

export function useReportAbuseTracking({ reportContext }: { reportContext: ReportContext }): Result {
	const user = useCurrentAuthenticatedUser();
	const track = useTracking();

	return {
		actions: {
			trackReportAbuse: useCallback(
				({ success }) => {
					switch (reportContext.type) {
						case 'petition':
							void track<{
								petition_id: string;
								user_id: string | undefined;
								successful: boolean;
								experiment_variation: 'variant';
							}>('petition_report_abuse', {
								petition_id: reportContext.petitionId,
								user_id: user?.id,
								successful: success,
								experiment_variation: 'variant',
							});
							return undefined;
						default:
							return undefined;
					}
				},
				[track, reportContext, user],
			),
		},
	};
}
