import { useCallback, useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { getExperimentName } from 'src/app/shared/hooks/experiment/fcm/types';
import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { isLoading, type State } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_DS2 } from './config';
import type { Ds2ExperimentData } from './types';
import { useDs2ExperimentFcm } from './useDs2ExperimentFcm';

const { isEnabled, experimentName } = FCM_EXPERIMENT_DS2;

export const useAsyncDs2ExperimentData = (
	prefetchedData?: Ds2ExperimentData,
): State<{ ds2ExperimentData: Ds2ExperimentData }> => {
	const fcm = useDs2ExperimentFcm();
	const locale = useLocale();
	const countryCode = useCountryCode();
	const utilityContext = useUtilityContext();

	const getVariation = useCallback(
		(name: string) => {
			const experiment = utilityContext.experiments.get(name);
			void experiment.treat();
			return experiment.variation;
		},
		[utilityContext],
	);

	// Stable identity for loading state.
	const loadingState = useMemo(() => ({ status: 'loading' as const }), []);

	return useMemo(() => {
		if (prefetchedData) {
			return { status: 'loaded', ds2ExperimentData: prefetchedData };
		}

		if (isLoading(fcm)) {
			return loadingState;
		}

		const callbackContext = {
			fcm: fcm.values,
			locale,
			countryCode,
		};
		const name = getExperimentName(callbackContext, experimentName);
		const fcmEnabled = isEnabled(callbackContext);

		return {
			status: 'loaded',
			ds2ExperimentData: {
				ds2FcmExperiment: {
					fcm: fcm.values,
					name,
					variation: fcmEnabled && name ? getVariation(name) : 'control',
				},
			},
		};
	}, [prefetchedData, fcm, loadingState, locale, countryCode, getVariation]);
};
