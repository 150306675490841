import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		experimentName: { type: 'string', optional: true },
	},
});

export const FCM_POLICY_ABUSE_PETITION_REPORT = createFcmConfig('policy_abuse_petition_report', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		experimentName: undefined,
	} satisfies ReturnType<typeof normalizer>,
});
