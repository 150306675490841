import type { PropsWithChildren } from 'react';
import React from 'react';

import { Box } from '@change-corgi/design-system/layout';

import type { DefaultLayoutProps } from '../shared/types';

import { Footer } from './footer';
import { Header } from './header';

export const LayoutV1 = ({
	hideHeader,
	hideFooter,
	hideBrowseLink,
	suppressHydrationWarning,
	children,
}: PropsWithChildren<DefaultLayoutProps> & { suppressHydrationWarning?: boolean }): React.JSX.Element | null => {
	if (hideFooter && hideHeader) {
		return (
			<Box
				// needed so that App can focus fallback on it
				data-maincontent-principal
				sx={{ height: '100%' }}
				role="main"
				suppressHydrationWarning={suppressHydrationWarning}
			>
				{children}
			</Box>
		);
	}

	return (
		<>
			{/* https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/ */}
			<Box suppressHydrationWarning={suppressHydrationWarning}>
				{!hideHeader && <Header hideBrowseLink={!!hideBrowseLink} sx={{ flexShrink: 0 }} />}
				<Box
					// needed so that App can focus fallback on it
					data-maincontent-principal
					// this is to avoid blank space after the footer
					// using flex:1 on the body currently doesn't work because of the banners being outside of the layout
					// and that can sometimes result in double scrollbars on desktop
					// 300px come from manually measuring the height of the footer and header
					// (not great but better than nothing for now)
					sx={{ minHeight: hideFooter ? undefined : [null, 'calc(100vh - 300px)'] }}
					data-qa="page-content"
					role="main"
				>
					{children}
				</Box>
				{!hideFooter && <Footer sx={{ flexShrink: 0 }} />}
			</Box>
		</>
	);
};
