import type { LoginState } from '@change-corgi/middle-end-gql-schema';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import type { CampaignTrackingErrorData, CampaignTrackingErrorName } from 'src/app/shared/hooks/campaignTracking';

import type { PageRenderingButton } from '../../../../shared/types';

type CommentLikeTrackingEvent =
	| 'supporter_voice_like_click'
	| 'supporter_voice_like_success'
	| 'supporter_voice_like_failure';

type CommentLikeTrackingOptions = {
	likeState: boolean;
	errors?: string;
};

type TrackLikeComment = (trackingEvent: CommentLikeTrackingEvent, options?: CommentLikeTrackingOptions) => void;

type Result = ModelHookResult<
	undefined,
	{
		trackLikeComment: TrackLikeComment;
		trackError: (eventName: CampaignTrackingErrorName, e?: unknown, errorData?: CampaignTrackingErrorData) => void;
	}
>;

type UseLikeCommentTrackingParams = {
	commentLikeTrackingData: {
		petition_id: string;
		comment_id: string;
		liker_id: string | undefined;
		page: PageRenderingButton;
	};
	loginState?: LoginState;
};

export function useLikeCommentTracking({ commentLikeTrackingData, loginState }: UseLikeCommentTrackingParams): Result {
	const { track, trackError } = useCampaignTracking();

	const trackLikeComment: TrackLikeComment = (trackingEvent, options) => {
		if (trackingEvent === 'supporter_voice_like_click') {
			void track(trackingEvent, {
				...commentLikeTrackingData,
				liked_state: options?.likeState,
				loginState,
			});
		}
		if (trackingEvent === 'supporter_voice_like_success') {
			void track(trackingEvent, {
				...commentLikeTrackingData,
				liked_state: options?.likeState,
			});
		}
		if (trackingEvent === 'supporter_voice_like_failure') {
			void track(trackingEvent, {
				...commentLikeTrackingData,
				liked_state: options?.likeState,
				errors: options?.errors,
			});
		}
	};

	return {
		actions: {
			trackLikeComment,
			trackError,
		},
	};
}
