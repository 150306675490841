import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
	},
});

export const FCM_NEW_SEARCH_PAGE_ACCESS_EXPERIMENT = createFcmConfig('new_search_page_access_experiment', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
	} satisfies ReturnType<typeof normalizer>,
});
