import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTE_PAGE_PREFILL_GUEST_USER_NAME_EXPERIMENT = createFcmConfig(
	'promote_page_prefill_guest_user_name_experiment',
	{
		normalizer: createJsonNormalizer(
			createShapeNormalizer({
				type: 'object',
				shape: {
					experiment_name: { type: 'string' },
				},
			}),
		),
		defaultValue: undefined,
	},
);
