import { useMappedLoadedState } from 'src/app/shared/hooks/state';
import type { StateWithoutError } from 'src/app/shared/utils/async';

import { useFcm } from './shared/fcm';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from './v1/header';
import { HEADER_HEIGHT as HEADER_HEIGHT_V2 } from './v2';

export function useHeaderHeight(): StateWithoutError<{ desktop: number; mobile: number }> {
	const fcm = useFcm();

	return useMappedLoadedState(fcm, ({ values }) =>
		values.frameRelaunch
			? { desktop: HEADER_HEIGHT_V2, mobile: HEADER_HEIGHT_V2 }
			: { desktop: HEADER_HEIGHT, mobile: HEADER_HEIGHT_MOBILE },
	);
}
