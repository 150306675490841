import { useMemo } from 'react';

import type { ServerError } from '@apollo/client';
import type { ValidationErrors } from 'final-form';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { NormalizedFormValues } from 'src/app/pages/petitionGamma/details/shared/types';
import type { SignatureUser } from 'src/app/shared/api/signature';

import { useFcm } from '../../fcm';
import { usePetitionDetails } from '../../petitionDetails';

import { isSampled } from './shared/sampled';

type ErrorContext = Readonly<{ submitError?: unknown; errors?: ValidationErrors }>;
export type SignatureErrorTrackingResult = Readonly<{
	trackSignError: (formData: NormalizedFormValues, errorContext: ErrorContext) => void;
	trackDoubleOptIn: () => void;
	trackExistingEmail: (user: SignatureUser) => void;
	trackPerimeterX: () => void;
}>;

type EventTrackingErrorData = Readonly<{
	type: 'sign_validation_error';
	petition_id: string;
	signature: string;
	server_error: boolean;
	reason?: string;
	first_name_valid: boolean;
	last_name_valid: boolean;
	email_valid: boolean;
	mutation_response?: string;
}>;
type EventTrackingDoubleOptInData = Readonly<{
	user_flow: string;
	context: string;
}>;
type EventTrackingExistingEmailData = Readonly<{
	guest_existing_user_state: string;
	connected_to_facebook: boolean;
	password_set: boolean;
	context: string;
}>;

function getExistingUserState(user: SignatureUser) {
	if (user.connectedToFacebook) return 'existing_fb_connected';
	if (user.passwordSet) return 'existing_pswd';
	return 'existing_no_pswd';
}

function stringifySubmitError(submitError: unknown) {
	if (submitError instanceof Error) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		if ((submitError as any).statusCode) {
			const response = submitError as ServerError;
			return JSON.stringify({
				status: response.statusCode,
				result: response.result,
			});
		}
		return submitError.message;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	if ((submitError as any).status) {
		const response = submitError as Response;
		return JSON.stringify({
			status: response.status,
		});
	}
	return JSON.stringify(submitError);
}

// eslint-disable-next-line max-lines-per-function
export function useSignatureErrorTracking(): SignatureErrorTrackingResult {
	const track = useTracking();
	const { signValidationErrorTrackSampleRate } = useFcm();
	const petition = usePetitionDetails();

	return useMemo(
		() => ({
			trackSignError: (formData: NormalizedFormValues, { submitError, errors }: ErrorContext) => {
				if (!isSampled(signValidationErrorTrackSampleRate)) return;

				const reason =
					(errors &&
						Object.entries(errors)
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.filter(([_name, error]) => error && error.key)
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.map(([name, error]) => `${name}: ${error.key}`)
							.join(' | ')) ||
					undefined;

				void track<EventTrackingErrorData>('error', {
					type: 'sign_validation_error',
					petition_id: petition.id,
					signature: JSON.stringify(formData),
					server_error: !!submitError,
					reason,
					first_name_valid: !errors?.firstName,
					last_name_valid: !errors?.lastName,
					email_valid: !errors?.email,
					...(submitError
						? {
								mutation_response: stringifySubmitError(submitError),
							}
						: {}),
				});
			},
			trackDoubleOptIn: () => {
				void track<EventTrackingDoubleOptInData>('double_opt_in_required', {
					user_flow: 'sign',
					context: 'petitions_show',
				});
			},
			trackExistingEmail: (user: SignatureUser) => {
				void track<EventTrackingExistingEmailData>('attempted_sign_with_existing_email', {
					guest_existing_user_state: getExistingUserState(user),
					connected_to_facebook: user.connectedToFacebook,
					password_set: user.passwordSet,
					context: 'petitions_show',
				});
			},
			trackPerimeterX: () => {
				void track('px_captcha_shown', { form: 'corgi_sign', petition_id: petition.id });
			},
		}),
		[petition, track, signValidationErrorTrackSampleRate],
	);
}
