import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { withPrefetchedData } from 'src/shared/prefetch';

import type { StateWithoutError } from 'src/app/shared/utils/async';
import { isLoading } from 'src/app/shared/utils/async';

import { prefetch } from './prefetch';
import { useFcm } from './shared/fcm';
import type { DefaultLayoutProps } from './shared/types';
import { LayoutV1 } from './v1';
import { LayoutV2 } from './v2';

function useHideBrowseLink(fromServer?: boolean): StateWithoutError<{ hideBrowseLink: boolean }> {
	const fcm = useFcm();
	const { experiments } = useUtilityContext();

	return useMemo(() => {
		if (fromServer !== undefined) {
			return { status: 'loaded', hideBrowseLink: fromServer };
		}
		if (isLoading(fcm)) {
			return { status: 'loading' };
		}
		if (!fcm.values.hideBrowseLink) {
			return { status: 'loaded', hideBrowseLink: false };
		}
		const experiment = experiments.get('new_search_page_access_experiment');
		void experiment.treat();
		return { status: 'loaded', hideBrowseLink: !experiment.variation.endsWith('control') };
	}, [fromServer, fcm, experiments]);
}

export const DefaultLayout = withPrefetchedData(
	({
		children,
		hideBrowseLink,
		...props
	}: PropsWithChildren<DefaultLayoutProps> & { suppressHydrationWarning?: boolean }): React.JSX.Element | null => {
		const fcm = useFcm();
		// for CSR pages (when hideBrowseLink is undefined), we need to check the experiment client-side
		const hideBrowseLinkState = useHideBrowseLink(hideBrowseLink);

		if (isLoading(fcm) || isLoading(hideBrowseLinkState)) {
			return <Loader size="m" my={256} mx="auto" />;
		}

		const Layout = fcm.values.frameRelaunch ? LayoutV2 : LayoutV1;

		return (
			<Layout hideBrowseLink={hideBrowseLinkState.hideBrowseLink} {...props}>
				{children}
			</Layout>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			await prefetch(context);
		},
	},
);
