import type { ComponentProps } from 'react';

import { HeaderLayout, HeaderLayoutButton, HeaderLayoutSearchButton } from '@change/tomato-layout';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';

import { Dropdown } from './components/Dropdown';
import { Logo } from './components/Logo';
import { useHeader } from './hooks/useHeader';

export const Header: React.FC<{ hideBrowseLink: boolean } & ComponentProps<'div'>> = ({ hideBrowseLink, ...props }) => {
	const {
		data: { navLinks, userIdentified, showMenu },
	} = useHeader({ hideBrowseLink });
	const { translate } = useI18n();

	return (
		<HeaderLayout
			logo={<Logo />}
			navLinks={navLinks}
			searchButton={
				showMenu && (
					<HeaderLayoutSearchButton
						href="/search"
						aria-label={translate('fe.components.header.search')}
						data-qa="header-search-link"
					>
						<Translate value="fe.components.header.search" />
					</HeaderLayoutSearchButton>
				)
			}
			userActionSlot={
				showMenu &&
				(userIdentified ? (
					<Dropdown hideBrowseLink={hideBrowseLink} />
				) : (
					<HeaderLayoutButton href="/login_or_join?user_flow=nav">
						<Translate value="fe.components.header.log_in" />
					</HeaderLayoutButton>
				))
			}
			mobileMenu={showMenu && <Dropdown hideBrowseLink={hideBrowseLink} mobile />}
			{...props}
		/>
	);
};
